let eventInit = false

// 判断当前页面是否需要展示隐私弹窗
const isCurrentPageShouldShowCookieBanner = () => {
  const { page_name } = window.SaPageInfo || {}
  const supportPage = ['page_home', 'page_goods_detail', 'page_category', 'page_daily_new', 'page_real_class', 'page_select_class', 'page_search', 'page_goods_group']

  if(!page_name)return 'unknown'

  if (supportPage.includes(page_name)) {
    return true
  }
  return false
}

function showPrivacyPop(cb, executeCount = 0){
  const result = isCurrentPageShouldShowCookieBanner()

  if(result === 'unknown'){
    if(executeCount <= 10){
      executeCount +=1
      setTimeout(showPrivacyPop,500,cb,executeCount)
    }else{
      cb(false)
    }
    return
  }

  cb(result)
}

function show(ins) {
  if (ins) {
    showPrivacyPop((isSupport)=>{
      if (isSupport) {
        ins.showAgreementIfNeed()
      }
    })

    if (!eventInit) {
      eventInit = true
      window._gb_app_ && window._gb_app_.$watch('$route', () => {
        showPrivacyPop((isSupport)=>{
          if (isSupport) {
            ins.showAgreementIfNeed()
          } else {
            ins.removeAgreement()
          }
        })
      })
    }
  }
}

export function showCookieBanner() {
  try {
    if (!window._gb_app_) {
      window.addEventListener('gbAppInfoInited', function () {
        var ins = window.PrivacySDK.PrivacySDK.getSingleton()
        show(ins)
      })
    } else {
      var ins = window.PrivacySDK.PrivacySDK.getSingleton()
      show(ins)
    }

  } catch (e) {
    console.error(e)
  }
}
